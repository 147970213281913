(function (w, d) {
    'use strict';

    /**
     * Initializer constructor
     *
     * @constructor
     */
    function Initializer() {
        this._onReady = [];
        this._onDisplay = [];
    }

    /**
     * Register an onDocumentReady callback
     *
     * @param {Function} callback Callback
     */
    Initializer.prototype.registerOnReady = function (callback) {
        this._onReady.push(callback);
    }

    /**
     * Register an onDisplay callback
     *
     * @param {Function} callback Callback
     */
    Initializer.prototype.registerOnDisplay = function (callback) {
        this._onDisplay.push(callback);
    }

    /**
     * Run initializers
     */
    Initializer.prototype.run = function () {
        for (var c = 0; c < this._onReady.length; ++c) {
            this._onReady[c]();
        }
    }

    /**
     * Run display routines
     */
    Initializer.prototype.display = function () {
        for (var c = 0; c < this._onDisplay.length; ++c) {
            this._onDisplay[c]();
        }
    }

    // Export as CommonJS module
    if (typeof exports !== 'undefined') {
        exports.Initializer = new Initializer();

        // Else create a global instance
    } else {
        w.Tollwerk.Initializer = new Initializer();
    }

})(typeof global !== 'undefined' ? global : this, document);

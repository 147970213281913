window.onload = function(){
    Tollwerk.Initializer.run();
    document.querySelector('html').classList.add('content-marged');

    function margeHeader() {
        if(document.querySelector('.Page') && document.querySelector('.Header__top') && document.querySelector('.NavMain')) {
            var headerHeight = document.querySelector('.Header__top').clientHeight;
            var navMainHeight = document.querySelector('.NavMain').clientHeight;

            if(window.innerWidth >= 720) {
                document.querySelector('.Page').style.marginTop = (headerHeight + navMainHeight - 2) + 'px';
            } else {
                document.querySelector('.Page').style.marginTop = headerHeight + 'px';
                document.querySelector('.NavMain').style.maxHeight = 'calc(100vh - ' + (headerHeight - 4) + 'px';
            }

            document.querySelector('.Header__top + .NavMain').style.top =  (headerHeight - 2) + 'px';
            document.querySelector('html').classList.add('content-marged');
        }
    }

    margeHeader();
    window.addEventListener('resize', margeHeader);
}